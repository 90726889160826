<template>
    <footer class="footer">
        <div class="copyright">
            <div class="back-top" @click.native="scrollToTop"></div>
            <span v-html="$t('copyright')+(new Date()).getFullYear()"></span>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        mounted () {
            window.scrollTo(0, 0)
        }
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    .footer {
        position: relative;
        z-index: 99;
        background: #000;
    }

    .copyright {
        position: relative;
        padding-top: 3.5rem;
        padding-bottom: 3rem;
        font-size: 1.6rem;
        text-align: center;
        color: #fff;
    }

    .back-top {
        position: absolute;
        right: 1.5rem;
        top: -3.5rem;
        height: 5.5rem;
        width: 5.5rem;
        z-index: 9;
        cursor: pointer;
        border-radius: 50%;
        background: #ec3a17 url('../../../public/img/detail/karamucho/back-to-top.png') no-repeat center;
        transition: .4s ease;

        &:hover {
            background-color: #f06d53
        }

        @include media(set-max-w, 800) {
            right: 1.5rem;
        }
    }

    .webp {
        .back-top {
            background: #ec3a17 url('../../../public/img/detail/karamucho/back-to-top.webp') no-repeat center;
        }
    }
</style>